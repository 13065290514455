<template>
    <div class="w-full h-screen h-full flex flex-col justify-center items-center">
        <img :src="require('@/master/assets/autralis/logo-rgb-portrait-expert.png')" style="width: 160px;"/>
        <button @click="start" class="mt-16 border border-gray-600 text-gray-600 px-16 py-3 rounded-lg font-medium hover:bg-gray-100">Start</button>
    </div>
</template>

<script>


import router from "@/router";
import {find} from "lodash";
import {MENU_KEYS} from "@/master/constants";

export default {
    name: 'Home',
    methods: {
        start: function () {
            if (this.$store.getters['master/isAuthenticated']) {
                if (this.$store.getters['master/isAuthenticatedFull']) {
                    // router.push('main');
                    const homeSubMenu = find(this.$store.getters['master/application'].subMenus, sm => sm.methodName === MENU_KEYS.GET_STARTED)
                    if (homeSubMenu) {
                        this.$store.commit("master/setSubMenu", homeSubMenu)
                    }
                    router.push('master/home');
                } else {
                    router.push('login-company');
                }
            } else {
                router.push('login');
            }
        }
    }
}
</script>
